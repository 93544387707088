.loader {
    --color: #a5a5b0;
    --size: 60px;
    position: relative;
    width: var(--size);
    height: var(--size);
}

.loader span {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid var(--color);
    border-bottom: 1px solid transparent;
    border-radius: 100%;
    animation: keyframes-rotate .8s infinite linear;
}

.loader svg {
    width: 100%;
    height: 100%;
    padding: 9%;
    fill: var(--color);
    animation: keyframes-blink .8s infinite ease-in-out;
}

@keyframes keyframes-rotate {
    0% {
        transform: rotate(360deg);
    }
}

@keyframes keyframes-blink {
    50% {
        opacity: 0.8;
    }
}



#superpuesto {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 24px;
    opacity: 1;
    z-index: 100;
    transition: opacity 1s ease-in-out;
}